import { render, createRoot } from "@wordpress/element";
import ChatBox from "./components/ChatBox";
// import { render, createRoot } from "@wordpress/element";

const chat_widget_box = document.getElementById("hs__live_chat_widget_box");
// console.log(chat_widget_box);

// const root = createRoot(document.getElementById("hs__live_chat_widget_box"))
const root = createRoot(chat_widget_box);

// alert('ChatBox is loaded');
// alert('ChatBox is loaded');

function live_chat_widget() {
  root.render(<ChatBox />);
}

document.addEventListener("DOMContentLoaded", () => {
  // const customer = document.getElementById("hs__live_chat_widget_box");
  if (chat_widget_box) {
    live_chat_widget();
  }
});
